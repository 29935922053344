<template>
  <div class="arbitratdetail-container" v-loading="loading">
    <div class="arbitratdetail-body">
      <div class="arbitratdetail-tab">
        <div class="arbitratdetail-tab-item">
          <div class="tab-item">
            <div class="tab-item-lable">案号</div>
            <div class="tab-item-content">
              {{ caseArbitratInfo.caseNo ? caseArbitratInfo.caseNo : "-" }}
            </div>
          </div>
          <div class="tab-item">
            <div class="tab-item-lable">标的</div>
            <div class="tab-item-content">
              {{
                caseArbitratInfo.arbitrationAmount
                  ? caseArbitratInfo.arbitrationAmount.toFixed(2) + "元"
                  : "-"
              }}
            </div>
          </div>
        </div>
        <div class="arbitratdetail-tab-item">
          <div class="tab-item">
            <div class="tab-item-lable">案由</div>
            <div class="tab-item-content">
              {{ caseArbitratInfo.remark ? caseArbitratInfo.remark : "-" }}
            </div>
          </div>
          <div class="tab-item">
            <div class="tab-item-lable">仲裁状态</div>
            <div class="tab-item-content">
              {{
                caseArbitratInfo.caseStatusDesc
                  ? caseArbitratInfo.caseStatusDesc
                  : "-"
              }}
            </div>
          </div>
        </div>
        <div class="arbitratdetail-tab-item">
          <div class="tab-item">
            <div class="tab-item-lable">办案秘书</div>
            <div class="tab-item-content">
              {{
                caseArbitratInfo.secretaryName
                  ? caseArbitratInfo.secretaryName
                  : "-"
              }}
            </div>
          </div>
          <div class="tab-item">
            <div class="tab-item-lable">仲裁员</div>
            <div class="tab-item-content">
              {{
                caseArbitratInfo.arbitratorName
                  ? caseArbitratInfo.arbitratorName
                  : "-"
              }}
            </div>
          </div>
        </div>
        <!-- <div class="arbitratdetail-tab-item">
          <div class="tab-item">
            <div class="tab-item-arbitrators-lable">仲裁员</div>
            <div class="tab-item-arbitrators-content">-</div>
          </div>
        </div> -->
      </div>
      <div class="arbitratdetail-menu">
        <el-tabs v-model="activeMenu" type="card" @tab-click="handleClick">
          <el-tab-pane label="证据材料">
            <div
              class="evidencEmaterial-content"
              v-if="evidencEmaterial.length > 0"
              v-loading="showOne"
            >
              <div
                class="evidencEmaterial-item"
                v-for="(item, index) in evidencEmaterial"
                :key="index"
              >
                <div
                  class="el-tab-item-content"
                  v-if="item.filetype != 'doc' && item.filetype != 'docx'"
                >
                  <span class="item-filename" @click="pdfPreview(item)">{{
                    item.fileName
                  }}</span>

                  <el-button type="text" size="small"
                    ><a
                      :href="
                        '/web/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      style=""
                      >下载</a
                    ></el-button
                  >
                </div>
              </div>

              <div class="el-tab-item-preview" v-if="fileListOne.isShowOne">
                <div v-if="fileListOne.filetype == 'pdf'">
                  <pdf
                    v-for="i in numPages"
                    :key="i"
                    :page="i"
                    ref="pdf"
                    :src="pdfUrl"
                  ></pdf>
                </div>
                <div
                  v-if="
                    fileListOne.filetype == 'png' ||
                    fileListOne.filetype == 'jpg' ||
                    fileListOne.filetype == 'jpeg'
                  "
                >
                  <iframe
                    @load="nowpreview"
                    id="iframe1"
                    :src="pngUrl"
                    frameborder="0"
                    width="100%"
                    height="600"
                  ></iframe>
                </div>

                <div v-if="fileListOne.filetype == 'mp4'">
                  <video-player
                    class="video-player vjs-custom-skin"
                    ref="videoPlayer"
                    name="videoPlayer"
                    :playsinline="true"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @pause="onPlayerPause($event)"
                    @ended="onPlayerEnded($event)"
                    @waiting="onPlayerWaiting($event)"
                    @playing="onPlayerPlaying($event)"
                    @loadeddata="onPlayerLoadeddata($event)"
                    @timeupdate="onPlayerTimeupdate($event)"
                    @canplay="onPlayerCanplay($event)"
                    @canplaythrough="onPlayerCanplaythrough($event)"
                    @statechanged="playerStateChanged($event)"
                    @ready="playerReadied"
                  ></video-player>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <div class="empty-img">
                <img src="../assets/images/index/empty.png" />
              </div>
              <div class="empty-text">暂无相关数据</div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="受理材料">
            <div
              class="acceptEmaterial-content"
              v-if="acceptEmaterial.length > 0"
              v-loading="showTwo"
            >
              <div
                class="acceptEmaterial-item"
                v-for="(item, index) in acceptEmaterial"
                :key="index"
              >
                <div
                  class="el-tab-item-content"
                  v-if="item.filetype != 'doc' && item.filetype != 'docx'"
                >
                  <span class="item-filename" @click="pdfPreviewTwo(item)">{{
                    item.fileName
                  }}</span>

                  <el-button type="text" size="small"
                    ><a
                      :href="
                        '/web/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      style=""
                      >下载</a
                    ></el-button
                  >
                </div>
              </div>

              <div class="el-tab-item-preview" v-if="fileListTwo.isShowTwo">
                <div v-if="fileListTwo.filetype == 'pdf'">
                  <pdf
                    v-for="i in numPagesTwo"
                    :key="i"
                    :page="i"
                    ref="pdf"
                    :src="pdfUrlTwo"
                  ></pdf>
                </div>
                <div
                  v-if="
                    fileListTwo.filetype == 'png' ||
                    fileListTwo.filetype == 'jpg' ||
                    fileListTwo.filetype == 'jpeg'
                  "
                >
                  <iframe
                    @load="nowpreviewTwo"
                    id="iframe2"
                    :src="pngUrlTwo"
                    frameborder="0"
                    width="100%"
                    height="600"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <div class="empty-img">
                <img src="../assets/images/index/empty.png" />
              </div>
              <div class="empty-text">暂无相关数据</div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="组庭材料">
            <div
              class="groupcourtEmaterial-content"
              v-if="groupcourtEmaterial.length > 0"
              v-loading="showThree"
            >
              <div
                class="groupcourtEmaterial-item"
                v-for="(item, index) in groupcourtEmaterial"
                :key="index"
              >
                <div
                  class="el-tab-item-content"
                  v-if="item.filetype != 'doc' && item.filetype != 'docx'"
                >
                  <span class="item-filename" @click="pdfPreviewThree(item)">{{
                    item.fileName
                  }}</span>

                  <el-button type="text" size="small"
                    ><a
                      :href="
                        '/web/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      style=""
                      >下载</a
                    ></el-button
                  >
                </div>
              </div>

              <div class="el-tab-item-preview" v-if="fileListThree.isShowThree">
                <div v-if="fileListThree.filetype == 'pdf'">
                  <pdf
                    v-for="i in numPagesThree"
                    :key="i"
                    :page="i"
                    ref="pdf"
                    :src="pdfUrlThree"
                  ></pdf>
                </div>
                <div
                  v-if="
                    fileListThree.filetype == 'png' ||
                    fileListThree.filetype == 'jpg' ||
                    fileListThree.filetype == 'jpeg'
                  "
                >
                  <iframe
                    @load="nowpreviewThree"
                    id="iframe3"
                    :src="pngUrlThree"
                    frameborder="0"
                    width="100%"
                    height="600"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <div class="empty-img">
                <img src="../assets/images/index/empty.png" />
              </div>
              <div class="empty-text">暂无相关数据</div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="结案材料">
            <div
              class="closecaseEmaterial-content"
              v-if="closecaseEmaterial.length > 0"
              v-loading="showFour"
            >
              <div
                class="closecaseEmaterial-item"
                v-for="(item, index) in closecaseEmaterial"
                :key="index"
              >
                <div
                  class="el-tab-item-content"
                  v-if="item.filetype != 'doc' && item.filetype != 'docx'"
                >
                  <span class="item-filename" @click="pdfPreviewFour(item)">{{
                    item.fileName
                  }}</span>

                  <el-button type="text" size="small"
                    ><a
                      :href="
                        '/web/web/preview/previewfile?download=1&filePath=' +
                        item.filePath
                      "
                      style=""
                      >下载</a
                    ></el-button
                  >
                </div>
              </div>

              <div class="el-tab-item-preview" v-if="fileListFour.isShowFour">
                <div v-if="fileListFour.filetype == 'pdf'">
                  <pdf
                    v-for="i in numPagesFour"
                    :key="i"
                    :page="i"
                    ref="pdf"
                    :src="pdfUrlFour"
                  ></pdf>
                </div>
                <div
                  v-if="
                    fileListFour.filetype == 'png' ||
                    fileListFour.filetype == 'jpg' ||
                    fileListFour.filetype == 'jpeg'
                  "
                >
                  <iframe
                    @load="nowpreviewFour"
                    id="iframe3"
                    :src="pngUrlFour"
                    frameborder="0"
                    width="100%"
                    height="600"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <div class="empty-img">
                <img src="../assets/images/index/empty.png" />
              </div>
              <div class="empty-text">暂无相关数据</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
// import { videoPlayer } from "vue-video-player";
// import "video.js/dist/video-js.css";
// import "vue-video-player/src/custom-theme.css";

export default {
  name: "ArbitratDetail",
  components: { pdf },
  data() {
    return {
      activeMenu: 0,

      // 证据材料
      evidencEmaterial: [],
      // 受理材料
      acceptEmaterial: [],
      // 组庭材料
      groupcourtEmaterial: [],
      // 结案材料
      closecaseEmaterial: [],

      uuid: "",
      caseArbitratInfo: {},

      pdfUrl: "", // 预览pdf
      pdfUrlTwo: "",
      pdfUrlThree: "",
      pdfUrlFour: "",

      pngUrl: "", // 预览图片
      pngUrlTwo: "",
      pngUrlThree: "",
      pngUrlFour: "",

      numPages: null,
      numPagesTwo: null,
      numPagesThree: null,
      numPagesFour: null,

      fileListOne: "",
      fileListTwo: "",
      fileListThree: "",
      fileListFour: "",

      loading: false,
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,

      playTime: "",
      current: "",
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  mounted() {
    // console.log(this.$route.path);
    if (this.$route.query.uuid) {
      this.uuid = this.$route.query.uuid;
    } else {
      let $arbitratCaseId = sessionStorage.getItem("arbitratCaseId");
      this.uuid = $arbitratCaseId;
    }
    this.getArbitratCaseInfo();
  },

  // beforeRouteLeave() {
  //   this.$refs.videoPlayer.player.pause();
  // },

  methods: {
    // 播放回调
    onPlayerPlay(player) {
      // console.log("player play!", player);
    },

    // 暂停回调
    onPlayerPause(player) {
      // console.log("player pause!", player);
    },

    // 视频播完回调
    onPlayerEnded($event) {
      // console.log(player);
    },

    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      let time = localStorage.getItem("cacheTime");
      if (player.cache_.currentTime - Number(time) > 0.1) {
        this.current = Number(time);
        this.playerReadied(player);
      } else {
        this.current = player.cache_.currentTime;
      }
    },

    // 已开始播放回调
    onPlayerPlaying($event) {
      // console.log(player);
    },

    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata($event) {
      // console.log(player);
    },

    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      this.playTime = player.cache_.currentTime;
      let playTime = player.cache_.currentTime;
      setTimeout(function () {
        localStorage.setItem("cacheTime", playTime);
      }, 500);

      let time = localStorage.getItem("cacheTime");
      if (player.cache_.currentTime - Number(time) > 2) {
        this.current = Number(time);
        this.playerReadied(player);
      } else {
        this.current = player.cache_.currentTime;
      }
    },

    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },

    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },

    //播放状态改变回调
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState);
    },

    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      // console.log('example player 1 readied', player);
      player.currentTime(this.current);
    },

    handleClick(e) {},
    //获取仲裁案件详情
    getArbitratCaseInfo() {
      let uuid = this.uuid;
      // console.log(caseId);
      this.loading = true;
      this.$axios
        .get(`/web/web/caseInfo/info?caseId=${uuid}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.loading = false;
            this.caseArbitratInfo = res.data.data;
            if (
              this.caseArbitratInfo.files &&
              (this.caseArbitratInfo.files != "" ||
                this.caseArbitratInfo.files != null)
            ) {
              this.caseArbitratInfo.files.map((item) => {
                if (
                  item.caseFileType == 0 ||
                  item.caseFileType == 1 ||
                  item.caseFileType == 2 ||
                  item.caseFileType == 3
                ) {
                  this.evidencEmaterial.push(item);
                }
                if (
                  item.caseFileType == 4 &&
                  item.noticeType == 2 &&
                  item.sendType == 1
                ) {
                  // console.log(1);
                  this.acceptEmaterial.push(item);
                  // console.log(this.acceptEmaterial);
                }
                if (
                  item.caseFileType == 4 &&
                  (item.noticeType == 4 || item.noticeType == 5) &&
                  item.sendType == 1
                ) {
                  this.groupcourtEmaterial.push(item);
                }
                if (
                  item.caseFileType == 4 &&
                  (item.noticeType == 6 || item.noticeType == 7) &&
                  item.sendType == 1
                ) {
                  this.closecaseEmaterial.push(item);
                }
              });
            }

            this.evidencEmaterial.map((item) => {
              this.$set(item, "isShowOne", false);
              return item;
            });

            this.acceptEmaterial.map((item) => {
              this.$set(item, "isShowTwo", false);
              return item;
            });

            this.groupcourtEmaterial.map((item) => {
              this.$set(item, "isShowThree", false);
              return item;
            });

            this.closecaseEmaterial.map((item) => {
              this.$set(item, "isShowFour", false);
              return item;
            });

            // 证据材料
            this.fileListOne = this.evidencEmaterial[0];
            this.fileListOne.isShowOne = true;
            if (this.fileListOne.filetype == "pdf") {
              this.pdfUrl = `/web/web/preview/previewfile?download=1&filePath=${this.fileListOne.filePath}`;
              let pdfUrlTask = pdf.createLoadingTask(this.pdfUrl);
              pdfUrlTask.promise
                .then((pdf) => {
                  this.numPages = pdf.numPages;
                })
                .catch((err) => {
                  this.$message({
                    message: err || "加载失败，pdf文件为空或者损坏",
                    type: "error",
                  });
                });
            }
            if (
              this.fileListOne.filetype == "png" ||
              this.fileListOne.filetype == "jpg" ||
              this.fileListOne.filetype == "jpeg"
            ) {
              this.pngUrl = `/web/web/preview/previewfile?filePath=${this.fileListOne.filePath}`;
            }

            if (this.fileListOne.filetype == "mp4") {
              this.playerOptions.sources[0].src = `/web/web/preview/previewfile?filePath=${this.fileListOne.filePath}`;
              this.playerOptions.autoplay = true;
            }

            // 受理材料
            this.fileListTwo = this.acceptEmaterial[0];
            this.fileListTwo.isShowTwo = true;
            if (this.fileListTwo.filetype == "pdf") {
              this.pdfUrlTwo = `/web/web/preview/previewfile?download=1&filePath=${this.fileListTwo.filePath}`;
              let pdfUrlTwoTask = pdf.createLoadingTask(this.pdfUrlTwo);
              pdfUrlTwoTask.promise
                .then((pdf) => {
                  this.numPagesTwo = pdf.numPages;
                })
                .catch((err) => {
                  this.$message({
                    message: err || "加载失败，pdf文件为空或者损坏",
                    type: "error",
                  });
                });
            }
            if (
              this.fileListTwo.filetype == "png" ||
              this.fileListTwo.filetype == "jpg" ||
              this.fileListTwo.filetype == "jpeg"
            ) {
              this.pngUrlTwo = `/web/web/preview/previewfile?filePath=${this.fileListTwo.filePath}`;
            }

            // 组庭材料
            this.fileListThree = this.groupcourtEmaterial[0];
            this.fileListThree.isShowThree = true;
            if (this.fileListThree.filetype == "pdf") {
              this.pdfUrlThree = `/web/web/preview/previewfile?download=1&filePath=${this.fileListThree.filePath}`;
              let pdfUrlThreeTask = pdf.createLoadingTask(this.pdfUrlThree);
              pdfUrlThreeTask.promise
                .then((pdf) => {
                  this.numPagesThree = pdf.numPages;
                })
                .catch((err) => {
                  this.$message({
                    message: err || "加载失败，pdf文件为空或者损坏",
                    type: "error",
                  });
                });
            }
            if (
              this.fileListThree.filetype == "png" ||
              this.fileListThree.filetype == "jpg" ||
              this.fileListThree.filetype == "jpeg"
            ) {
              this.pngUrlThree = `/web/web/preview/previewfile?filePath=${this.fileListThree.filePath}`;
            }

            // 结案材料
            this.fileListFour = this.closecaseEmaterial[0];
            this.fileListFour.isShowFour = true;
            if (this.fileListFour.filetype == "pdf") {
              this.pdfUrlFour = `/web/web/preview/previewfile?download=1&filePath=${this.fileListFour.filePath}`;
              let pdfUrlFourTask = pdf.createLoadingTask(this.pdfUrlFour);
              pdfUrlFourTask.promise
                .then((pdf) => {
                  this.numPagesFour = pdf.numPages;
                })
                .catch((err) => {
                  this.$message({
                    message: err || "加载失败，pdf文件为空或者损坏",
                    type: "error",
                  });
                });
            }
            if (
              this.fileListFour.filetype == "png" ||
              this.fileListFour.filetype == "jpg" ||
              this.fileListFour.filetype == "jpeg"
            ) {
              this.pngUrlFour = `/web/web/preview/previewfile?filePath=${this.fileListFour.filePath}`;
            }
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          return;
          // this.$message({
          //   message: "请求异常，服务器出错",
          // });
        });
    },

    // 点击预览
    // 证据材料
    pdfPreview(e) {
      this.showOne = true;
      this.fileListOne = e;
      // let nowisShow = e.isShowOne;
      this.evidencEmaterial.map((item) => {
        item.isShowOne = false;
      });
      // e.isShowOne = !nowisShow;
      e.isShowOne = true
      if (e.filetype == "pdf") {
        this.pdfUrl = `/web/web/preview/previewfile?download=1&filePath=${e.filePath}`;
        let pdfUrlTask = pdf.createLoadingTask(this.pdfUrl);
        pdfUrlTask.promise
          .then((pdf) => {
            this.showOne = false;
            this.numPages = pdf.numPages;
          })
          .catch((err) => {
            this.showOne = false;
            this.$message({
              message: err || "加载失败，pdf文件为空或者损坏",
              type: "error",
            });
          });
      }
      if (e.filetype == "png" || e.filetype == "jpg" || e.filetype == "jpeg") {
        this.pngUrl = `/web/web/preview/previewfile?filePath=${e.filePath}`;
        this.showOne = false;
      }

      if (e.filetype == "mp4") {
        this.playerOptions.sources[0].src = `/web/web/preview/previewfile?filePath=${e.filePath}`;
        this.playerOptions.autoplay = true;
        this.showOne = false;
      }
    },

    nowpreview() {
      let iframe1 = document.getElementById("iframe1");
      let doc1 = iframe1.contentWindow.document;
      let img1 = doc1.getElementsByTagName("img")[0];
      img1.style.width = 100 + "%";
    },

    // 受理材料
    pdfPreviewTwo(e) {
      this.showTwo = true;
      this.fileListTwo = e;
      // let nowisShowTwo = e.isShowTwo;
      this.acceptEmaterial.map((item) => {
        item.isShowTwo = false;
      });
      // e.isShowTwo = !nowisShowTwo;
      e.isShowTwo = true
      if (e.filetype == "pdf") {
        this.pdfUrlTwo = `/web/web/preview/previewfile?download=1&filePath=${e.filePath}`;
        let pdfUrlTwoTask = pdf.createLoadingTask(this.pdfUrlTwo);
        pdfUrlTwoTask.promise
          .then((pdf) => {
            this.numPagesTwo = pdf.numPages;
            this.showTwo = false;
          })
          .catch((err) => {
            this.showTwo = false;
            this.$message({
              message: err || "加载失败，pdf文件为空或者损坏",
              type: "error",
            });
          });
      }
      if (e.filetype == "png" || e.filetype == "jpg" || e.filetype == "jpeg") {
        this.pngUrlTwo = `/web/web/preview/previewfile?filePath=${e.filePath}`;
        this.showTwo = false;
      }
    },

    nowpreviewTwo() {
      let iframe2 = document.getElementById("iframe2");
      let doc2 = iframe2.contentWindow.document;
      let img2 = doc2.getElementsByTagName("img")[0];
      img2.style.width = 100 + "%";
    },

    // 组庭材料
    pdfPreviewThree(e) {
      this.showThree = true;
      this.fileListThree = e;
      // let nowisShowThree = e.isShowThree;
      this.groupcourtEmaterial.map((item) => {
        item.isShowThree = false;
      });
      // e.isShowThree = !nowisShowThree;
      e.isShowThree = true
      if (e.filetype == "pdf") {
        this.pdfUrlThree = `/web/web/preview/previewfile?download=1&filePath=${e.filePath}`;
        let pdfUrlThreeTask = pdf.createLoadingTask(this.pdfUrlThree);
        pdfUrlThreeTask.promise
          .then((pdf) => {
            this.numPagesThree = pdf.numPages;
            this.showThree = false;
          })
          .catch((err) => {
            this.showThree = false;
            this.$message({
              message: err || "加载失败，pdf文件为空或者损坏",
              type: "error",
            });
          });
      }
      if (e.filetype == "png" || e.filetype == "jpg" || e.filetype == "jpeg") {
        this.pngUrlThree = `/web/web/preview/previewfile?filePath=${e.filePath}`;
        this.showThree = false;
      }
    },

    nowpreviewThree() {
      let iframe3 = document.getElementById("iframe3");
      let doc3 = iframe3.contentWindow.document;
      let img3 = doc3.getElementsByTagName("img")[0];
      img3.style.width = 100 + "%";
    },

    // 结案材料
    pdfPreviewFour(e) {
      this.showFour = true;
      this.fileListFour = e;
      // let nowisShowFour = e.isShowFour;
      this.closecaseEmaterial.map((item) => {
        item.isShowFour = false;
      });
      // e.isShowFour = !nowisShowFour;
      e.isShowFour = true
      if (e.filetype == "pdf") {
        this.pdfUrlFour = `/web/web/preview/previewfile?download=1&filePath=${e.filePath}`;
        let pdfUrlFourTask = pdf.createLoadingTask(this.pdfUrlFour);
        pdfUrlFourTask.promise
          .then((pdf) => {
            this.numPagesFour = pdf.numPages;
            this.showFour = false;
          })
          .catch((err) => {
            this.showFour = false;
            this.$message({
              message: err || "加载失败，pdf文件为空或者损坏",
              type: "error",
            });
          });
      }
      if (e.filetype == "png" || e.filetype == "jpg" || e.filetype == "jpeg") {
        this.pngUrlFour = `/web/web/preview/previewfile?filePath=${e.filePath}`;
        this.showFour = false;
      }
    },

    nowpreviewFour() {
      let iframe4 = document.getElementById("iframe4");
      let doc4 = iframe4.contentWindow.document;
      let img4 = doc4.getElementsByTagName("img")[0];
      img4.style.width = 100 + "%";
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/arbitratdetail.scss";
</style>